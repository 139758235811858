const cyrillicPattern = /^[\u0400-\u04FF]+$/;

function IsLower(char) {
  if (char == char.toLowerCase()) return true;
  else return false;
}

function LatToCyr(s) {
  let sb = "";
  let i = 0;
  while (i < s.Length) {
    let ch = s[i];
    let lc = IsLower(ch);
    ch = ch.toUpperCase();
    if (ch == "J" && i != s.Length - 1) {
      i++;
      ch = s[i].toUpperCase();
      switch (ch) {
        case "O":
          sb = sb.concat(ReturnRegister("Ё", lc));
          break;
        case "H":
          if (i + 1 < s.Length && s[i + 1].toUpperCase() == "Н") {
            sb = sb.concat(ReturnRegister("Ъ", lc));
            i++;
          } else sb = sb.concat(ReturnRegister("Ь", lc));
          break;
        case "U":
          sb = sb.concat(ReturnRegister("Ю", lc));
          break;
        default:
          sb = sb.concat(ReturnRegister(ch, lc));
          break;
      }
    } else if (ch == "Y" && i != s.Length - 1) {
      i++;
      ch = s[i].toUpperCase();
      switch (ch) {
        case "A":
          sb = sb.concat(ReturnRegister("Я", lc));
          break;
        default:
          i--;
          sb = sb.concat(ReturnRegister("Й", lc));
          break;
      }
    } else if (i + 1 < s.Length && s[i + 1].toUpperCase() == "H") {
      switch (ch) {
        case "Z":
          sb = sb.concat(ReturnRegister("Ж", lc));
          break;
        case "K":
          sb = sb.concat(ReturnRegister("Х", lc));
          break;
        case "C":
          sb = sb.concat(ReturnRegister("Ч", lc));
          break;
        case "S":
          if (i + 2 < s.Length && s[i + 2].toUpperCase() == "Н") {
            sb = sb.concat(ReturnRegister("Щ", lc));
            i++;
          } else sb = sb.concat(ReturnRegister("Ш", lc));
          break;
        case "E":
          sb = sb.concat(ReturnRegister("Э", lc));
          break;
        case "I":
          sb = sb.concat(ReturnRegister("Ы", lc));
          break;
        default:
          sb = sb.concat(ReturnRegister(ch, lc));
          break;
      }
      i++;
    } else {
      switch (ch) {
        case "A":
          sb = sb.concat(ReturnRegister("А", lc));
          break;
        case "B":
          sb = sb.concat(ReturnRegister("Б", lc));
          break;
        case "V":
          sb = sb.concat(ReturnRegister("В", lc));
          break;
        case "G":
          sb = sb.concat(ReturnRegister("Г", lc));
          break;
        case "D":
          sb = sb.concat(ReturnRegister("Д", lc));
          break;
        case "E":
          sb = sb.concat(ReturnRegister("Е", lc));
          break;
        case "Z":
          sb = sb.concat(ReturnRegister("Ж", lc));
          break;
        case "I":
          sb = sb.concat(ReturnRegister("И", lc));
          break;
        case "Y":
          sb = sb.concat(ReturnRegister("Й", lc));
          break;
        case "K":
          sb = sb.concat(ReturnRegister("К", lc));
          break;
        case "L":
          sb = sb.concat(ReturnRegister("Л", lc));
          break;
        case "M":
          sb = sb.concat(ReturnRegister("М", lc));
          break;
        case "N":
          sb = sb.concat(ReturnRegister("Н", lc));
          break;
        case "O":
          sb = sb.concat(ReturnRegister("О", lc));
          break;
        case "P":
          sb = sb.concat(ReturnRegister("П", lc));
          break;
        case "R":
          sb = sb.concat(ReturnRegister("Р", lc));
          break;
        case "S":
          sb = sb.concat(ReturnRegister("С", lc));
          break;
        case "T":
          sb = sb.concat(ReturnRegister("Т", lc));
          break;
        case "U":
          sb = sb.concat(ReturnRegister("У", lc));
          break;
        case "F":
          sb = sb.concat(ReturnRegister("Ф", lc));
          break;
        case "C":
          sb = sb.concat(ReturnRegister("С", lc));
          break;
        default:
          sb = sb.cncat(ReturnRegister(ch, lc));
          break;
      }
    }

    i++;
  }
  return sb;
}

function CyrToLatChar(ch, withY) {
  switch (ch.charCodeAt(0)) {
    case 1040:
      return "A";
    case 1041:
      return "B";
    case 1042:
      return "V";
    case 1043:
      return "G";
    case 1044:
      return "D";
    case 1045:
      return "E";
    case 1025:
      return "JO";
    case 1046:
      return "ZH";
    case 1047:
      return "Z";
    case 1048:
      return withY ? "I" : "Y";
    case 1049:
      return withY ? "Y" : "I";
    case 1050:
      return "K";
    case 1051:
      return "L";
    case 1052:
      return "M";
    case 1053:
      return "N";
    case 1054:
      return "O";
    case 1055:
      return "P";
    case 1056:
      return "R";
    case 1057:
      return "S";
    case 1058:
      return "T";
    case 1059:
      return "U";
    case 1060:
      return "F";
    case 1061:
      return "KH";
    case 1062:
      return "C";
    case 1063:
      return "CH";
    case 1064:
      return "SH";
    case 1065:
      return "SHH";
    case 1066:
      return "JHH";
    case 1067:
      return "IH";
    case 1068:
      return "JH";
    case 1069:
      return "EH";
    case 1070:
      return "JU";
    case 1071:
      return "YA";
    default: {
      console.log(ch);
      return ch;
    }
  }
}

function CyrToLat(s, withY = false) {
  let sb = "";

  Array.from(s).forEach((ch) => {
    let upCh = ch.toUpperCase();
    let lat = CyrToLatChar(upCh, withY);

    if (ch != upCh) lat = lat.toLowerCase();

    sb = sb.concat(lat);
  });

  return sb;
}

function TranslitToLat(s, withY) {
  if (isEmpty(s)) return s;

  let sb = "";
  let sb2 = "";

  let lastSymbol = -1; // 0 - cyr, 1 - lat, 2 - other

  Array.from(s).forEach((ch) => {
    var c = ch.toLowerCase();

    if (IsCyr(c)) {
      lastSymbol = 0;
      sb2 = sb2.concat(c);
    } else if (IsLat(c)) {
      if (lastSymbol == 0) {
        sb = sb.concat(CyrToLat(sb2, withY));
        sb2 = "";
      }

      lastSymbol = 1;
      sb = sb.concat(c);
    } else {
      if (lastSymbol == 1) {
        sb = sb.concat(LatToCyr(sb2));
        sb2 = "";
      } else if (lastSymbol == 0) {
        sb = sb.concat(CyrToLat(sb2, withY));
        sb2 = "";
      }

      sb = sb.concat(c);
      lastSymbol = 2;
    }
  });

  if (lastSymbol == 1) {
    sb = sb.concat(LatToCyr(sb2));
  } else if (lastSymbol == 0) {
    sb = sb.concat(CyrToLat(sb2, withY));
  }

  return sb;
}

//function IsCyr(ch) { return (ch >= '‡' && ch <= 'ˇ' || ch == '∏'); }
//function IsCyr(ch) { return 'ÈˆÛÍÂÌ„¯˘Áı˙Ù˚‚‡ÔÓÎ‰Ê˝ˇ˜ÒÏËÚ¸·˛∏'.includes(ch) }
function IsCyr(ch) {
  return cyrillicPattern.test(ch);
}

function IsLat(ch) {
  return ch >= "a" && ch <= "z";
}
function ReturnRegister(ch, toLowerCase) {
  return toLowerCase ? ch.toLowerCase() : ch;
}

function isEmpty(str) {
  if (str.trim() == "") return true;

  return false;
}

export { TranslitToLat };
