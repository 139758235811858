<template>
  <v-app>
    <v-app-bar app color="dark" dark clipped-left>
      <div class="d-flex align-center">
        <router-link :to="'/'" style="text-decoration: none; color: #fff;">
          <span>Play<span style="color:#ABCC25">ON</span> - The ultimate sport player network</span>
        </router-link>
      </div>

      <!-- <v-spacer></v-spacer>
      <v-btn v-if="!isUserAuthenticated" text to="/SignIn">
        <v-icon>mdi-login</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-navigation-drawer v-if="isUserAuthenticated" style="margin-top:64px" permanent expand-on-hover fixed clipped>
      <v-list>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Admin
            </v-list-item-title>
            <v-list-item-subtitle>{{ this.userEmail }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item to="/users">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Users</v-list-item-title>
        </v-list-item>
        <v-list-item to="/clubs">
          <v-list-item-icon>
            <v-icon>mdi-tennis</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sport clubs</v-list-item-title>
        </v-list-item>
        <v-list-item to="/news">
          <v-list-item-icon>
            <v-icon>mdi-newspaper</v-icon>
          </v-list-item-icon>
          <v-list-item-title>News</v-list-item-title>
        </v-list-item>
        <v-list-item to="/userreports">
          <v-list-item-icon>
            <v-icon>mdi-account-alert</v-icon>
          </v-list-item-icon>
          <v-list-item-title>User reports</v-list-item-title>
        </v-list-item>
        <v-list-item @click="signOut()">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <!-- <div v-if="showImage">
              <v-img src="./assets/logoText.png" width="30%" style="margin: auto; margin-top: 150px;" />
      </div> -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

import { accountSignOut, isAuthenticated } from './services/auth-service'
export default {
  name: 'App',
  data: () => ({
    user: null,
    isUserAuthenticated: false,
    userEmail: null,
    // showImage: false,
  }),
  watch: {
    $route() {
      this.checkUserAuthentication();
    },
  },
  // created() {
  //   if (this.$route.path.match(/^\/link\//)) {
  //     this.showImage = true;
  //   }
  // },
  mounted() {
    this.checkUserAuthentication()
  },
  methods: {
    signOut() {
      // eslint-disable-next-line 
      accountSignOut().then(res => {
        this.$router.push({
          name: "Landing"
        })
      })
    },
    checkUserAuthentication() {
      this.user = isAuthenticated()
      if (this.user) {
        this.isUserAuthenticated = true
        this.userEmail = this.user.email
      } else {
        this.isUserAuthenticated = false
        this.userEmail = null
      }

      //console.log("isUserAuthenticated: ", this.isUserAuthenticated)
    }
  }
};
</script>
