function divideWord(word) {
  word = word.toLowerCase();

  const syllables = {};

  for (let i = 1; i <= word.length; i++) {
    syllables[i] = [];

    for (let j = 0; j <= word.length - i; j++) {
      syllables[i].push(word.substring(j, j + i));
    }
  }

  return syllables;
}

export { divideWord };
