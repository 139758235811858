/* eslint-disable */
import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";


function signIn(loginData) {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, loginData.email, loginData.password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                if (user.uid == "wUu74W1nLzcGFXBLAVZKJ0HtX5A2") {
                    resolve(user)
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
                reject(errorMessage)
            });
    })
}

function accountSignOut() {
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
            let singOut = true
            resolve(singOut)
        }).catch((error) => {
            // An error happened.
            reject(error)
        });
    })
}

function isAuthenticated(){
    const auth = getAuth();
    const user = auth.currentUser;
    return user
}

export {
    signIn,
    accountSignOut,
    isAuthenticated,
}