import { collection, getDocs, doc, updateDoc, addDoc, deleteDoc } from "firebase/firestore"; 
import {db} from '@/firebase'

async function getRegions() {
    let regionList = []
    const querySnapshot = await getDocs(collection(db, "regions"));
    querySnapshot.forEach((doc) => {   
        let region = doc.data()
        region.Id = doc.id
        regionList.push(region)
    })
    return regionList
}

async function updateRegion(ObjectData) {
    try {
        const docRef = doc(db, "regions", ObjectData.Id)
        const updatedObject = { ...ObjectData}
        await updateDoc(docRef, updatedObject);
        return { status: "success" }
    } catch (e) {
        console.log(e)
        return e
    }
}

async function addRegion(ObjectData) {
    try {
        const updatedObject = { ...ObjectData}
        const docRef = await addDoc(collection(db, "regions"), {...updatedObject})
        const docRefId = docRef.id
        return { docRefId }
    } catch (e) {
        console.log(e)
        return e
    }
}

async function getCountries() {
    let list = []
    const querySnapshot = await getDocs(collection(db, "countries"));
    querySnapshot.forEach((doc) => {   
        let element = doc.data()
        element.Id = doc.id
        list.push(element)
    })
    return list
}

async function addCountry(ObjectData) {
    try {
        const updatedObject = { ...ObjectData}
        const docRef = await addDoc(collection(db, "countries"), {...updatedObject})
        const docRefId = docRef.id
        return { docRefId }
    } catch (e) {
        console.log(e)
        return e
    }
}

async function deleteCountry(ObjectData) {
    try {
        // const docRef = doc(db, "countries", ObjectData.Id)
        // const updatedObject = { ...ObjectData}
        // await updateDoc(docRef, updatedObject);
        await deleteDoc(doc(db, "countries", ObjectData.Id));
        return { status: "success" }
    } catch (e) {
        console.log(e)
        return e
    }
}

async function deleteRegion(ObjectData) {
    try {
        await deleteDoc(doc(db, "regions", ObjectData.Id));
        return { status: "success" }
    } catch (e) {
        console.log(e)
        return e
    }
}

export {
    getRegions,
    updateRegion,
    addRegion,
    addCountry,
    getCountries,
    deleteCountry,
    deleteRegion
}