import Router from 'vue-router'
import SignIn from '../components/SignIn'
import Users from '../views/Users'
import Clubs from '../views/Clubs'
import News from '../views/News'
import Topics from '../views/Topics'
import Landing from '../views/Landing'
import PrivacyPolicy from '../views/PrivacyPolicy'
import ToS from '../views/ToS'
import UserReports from '../views/UserReports'


import { getAuth } from "firebase/auth"

/* eslint-disable */

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'Landing',
      component: Landing
    },
    {
      path: '/signin',
      name: 'SignIn',
      component: SignIn
    },
    {
      path: '/Users',
      name: 'Users',
      component: Users,
      meta: { requiresAuth: true },
    },
    {
      path: '/Clubs',
      name: 'Clubs',
      component: Clubs,
      meta: { requiresAuth: true },
    },
    {
      path: '/News',
      name: 'News',
      component: News,
      meta: { requiresAuth: true },
    },
    {
      path: '/UserReports',
      name: 'UserReports',
      component: UserReports,
      meta: { requiresAuth: true },
    },
    {
      path: '/Topics',
      name: 'Topics',
      component: Topics,
      meta: { requiresAuth: true },
    },
    {
      path: '/PrivacyPolicy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },
    {
      path: '/ToS',
      name: 'ToS',
      component: ToS
    },
    {
      path: '/ToU',
      name: 'ToS',
      component: ToS
    },
    // {
    //   path: '/link/:id',
    //   beforeEnter(to, from, next) {
    //     window.location.href = `unitydl://playon?${to.params.id}`;
    //   }
    // },
    {
      path: '/link/:id',
      beforeEnter: (to, from, next) => {
        const url = `unitydl://playon?${to.params.id}`
        const confirmed = window.confirm(`Open application "PlayON" ?`)
        if (confirmed) {
          window.location.href = url;
          next('/')
        } else {
          next('/')
        }
      }
    },
    {
      path: '/:catchAll(.*)*',
      redirect: '/'
    },
  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (requiresAuth && !currentUser) next('/')
  //else if (!requiresAuth && currentUser && to.path === "/") next('/')
  else next()

})

export default router