<template>
        <div>
      <span> Topics</span>
    </div>
</template>
  
  <script>
  import signIn from '../components/SignIn.vue'
  /* eslint-disable */
  export default {
    components: {signIn},
    name: 'Topics',
    data: () => ({
      //
    }),
  };
  </script>
  