<template>
      <div>
        <NewsTable/>
    </div>
</template>
  
  <script>

import NewsTable from '../components/NewsTable.vue'
  /* eslint-disable */
  export default {
    components: {NewsTable},
    name: 'News',
    data: () => ({
      //
    }),
  };
  </script>
  