<template>
    <v-row style="margin-left:50px; margin-top: 50px; justify-content: center;">
        <v-card width="95%" elevation="5" outlined style="border-radius:20px">
            <div style="margin-top:15px" />
            <v-data-table :search="search" :headers="headers" :items="items" :items-per-page="40">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Regions</v-toolbar-title><br />
                        <v-spacer></v-spacer>
                        <v-btn outlined style="margin-right:20px" @click="openNewDialog()">
                            <v-icon>mdi-plus</v-icon> Add new
                        </v-btn>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <v-icon v-if="item.Name !== 'Add new one'" small class="mr-2" @click="viewCard(item)">
                        mdi-eye
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <!--Uploading-->
        <v-dialog v-model="upLoading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!--Dialog---->
        <v-dialog width="50%" v-model="dialog" persistent transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="dark">
                    <v-btn icon dark @click="cleanData()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn v-if="isNew" dark text @click="addNewRegion()">
                            Add
                        </v-btn>
                        <v-btn v-if="!isNew" dark color="error" text @click="deleteThisRegion()">
                            Delete
                        </v-btn>
                        <v-btn v-if="!isNew" dark text @click="saveRegion()">
                            Save
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div v-if="isNew" style="margin:10px">
                    <v-subheader>Select country of add new</v-subheader>
                    <v-row>
                        <v-col>
                            <v-select dense :items="countries" label="Country" outlined
                                v-model="itemData.Country"></v-select>
                        </v-col>
                        <v-col v-if="itemData.Country == 'Add new one'">

                        <v-select dense outlined v-model="newCountry" :items="countriesOptions" item-text="name" item-value="isoCode"/>
                        <!-- <v-text-field dense label="Country Name" outlined v-model="country.Name"></v-text-field> -->
                        </v-col>
                        <v-col v-if="itemData.Country == 'Add new one'">
                            <v-btn outlined style="margin-right:20px; height: 40px" @click="addNewCountry()">
                            <v-icon>mdi-plus</v-icon> Add new
                        </v-btn>
                        </v-col>
                        <v-col v-if="(itemData.Country != 'Add new one') && itemData.Country" >
                            <v-btn outlined style="margin-right:20px; height: 40px" @click="deleteCountry(itemData.Country)">
                            <v-icon>mdi-minus</v-icon> Delete country
                        </v-btn>
                        </v-col>
                    </v-row>
                    <v-text-field v-if="(itemData.Country != 'Add new one')" dense label="Region Name" outlined v-model="itemData.Name"></v-text-field>
                </div>

                <div v-if="!isNew" style="margin:10px">
                    <v-row>
                        <v-col>
                            <v-select dense :items="countries" label="Country" outlined
                                v-model="itemData.Country"></v-select>
                        </v-col>
                        <v-col v-if="itemData.Country == 'Add new one'">
                        <v-text-field dense label="Country Name" outlined v-model="country.Name"></v-text-field>
                        </v-col>
                        <v-col v-if="itemData.Country == 'Add new one'">
                            <v-btn outlined style="margin-right:20px; height: 40px" @click="addNewCountry()">
                            <v-icon>mdi-plus</v-icon> Add new
                        </v-btn>
                        </v-col>
                        <v-col v-if="(itemData.Country != 'Add new one') && itemData.Country" >
                            <v-btn outlined style="margin-right:20px; height: 40px" @click="deleteCountry(itemData.Country)">
                            <v-icon>mdi-minus</v-icon> Delete country
                        </v-btn>
                        </v-col>
                    </v-row>
                    <v-text-field dense label="Region Name" outlined v-model="itemData.Name"></v-text-field>
                </div>
            </v-card>
        </v-dialog>

    </v-row>
</template>
<script>
/* eslint-disable */

import {
    getRegions,
    updateRegion,
    addRegion,
    addCountry,
    getCountries,
    deleteCountry,
    deleteRegion
} from '../services/region-service'

import ISOCodes from '../services/isoCodes.json'

export default {
    name: 'ClubsTable',
    data: () => ({
        newCountry: null,
        countriesOptions: [],
        items: [],
        isNew: false,
        dialogTitle: null,
        dialog: false,
        upLoading: false,
        itemData: [],
        countries: [],
        countriesRaw: [],
        country: {},
        headers: [
            {
                text: "ID",
                align: "start",
                sortable: false,
                value: "Id",
            },
            {
                text: "Country",
                align: "start",
                sortable: true,
                value: "Country",
            },
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "Name",
            },
            {
                text: 'Actions',
                value: 'actions'
            },
        ],
        search: '',
    }),
    mounted() {
        this.getData()
        this.countriesOptions = ISOCodes;
    },
    methods: {
        getData() {
            getRegions().then((res) => {
                // this.items = [{Name: "Add new one"}]
                // res.forEach(element => {
                //     this.items.push(element)
                // });
                // console.log("this.items", this.items)
                this.items = res
            })
            getCountries().then((res) => {
                this.countriesRaw = res
                this.countries = ["Add new one"]
                res.forEach(element => {
                    this.countries.push(element.Name)
                });
                console.log("this.countries", this.countries)
            })
        },

        openNewDialog() {
            this.dialogTitle = "Add a new region"
            this.isNew = true
            this.dialog = true
        },

        viewCard(item) {
            this.itemData = item
            this.dialogTitle = "Region Id: " + this.itemData.Id
            this.isNew = false
            this.dialog = true
        },

        cleanData() {
            this.itemData = []
            this.dialogTitle = null
            this.isNew = false
            this.upLoading = false
            this.items = []
            this.countries = []
            this.countriesRaw = []
            this.dialog = false
            this.country = null
            this.getData()
        },

        async addNewRegion() {
            this.upLoading = true
            console.log("this.itemData", this.itemData)
            this.countriesRaw.forEach(element => {
                if (element.Name === this.itemData.Country){
                    this.itemData.CountryId = element.Id
                }
            });
            await addRegion(this.itemData).then((res) => {
                console.log("New Region has been ADDED", res)
                this.cleanData()
            })
        },

        async saveRegion() {
            this.upLoading = true
            await updateRegion(this.itemData).then((res) => {
                console.log("Region has been UPDATED", res)
                this.cleanData()
            })   
        },

        async addNewCountry() {
            this.upLoading = true
            const newCountryObject = this.countriesOptions.find(element => element.isoCode === this.newCountry)
            const newCountryData = {
                Name: newCountryObject.name,
                Code: newCountryObject.isoCode
            }
            await addCountry(newCountryData).then((res) => {
                console.log("New Country has been ADDED", res)
                this.cleanData()
            })
        },

        async deleteCountry(country) {
            this.upLoading = true
            let countryToDelete = {}
            this.countriesRaw.forEach(element => {
                if (element.Name === country) {
                    countryToDelete = element
                }
            });
            await deleteCountry(countryToDelete).then((res) => {
                console.log("Country has been DELETED", res)
                this.cleanData()
            })
        },

        async deleteThisRegion() {
            this.upLoading = true
            await deleteRegion(this.itemData).then((res) => {
                console.log("Region has been DELETED", res)
                this.cleanData()
            })
        }

    },
}
</script>
