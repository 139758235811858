<template>
  <v-row style="margin-left:50px; margin-top: 50px; justify-content: center;">
    <v-card width="95%" 
  elevation="5"
  outlined
  style="border-radius:20px">
  <div style="margin-top:15px"/>
      <v-data-table :search="search" :headers="headers" :items="users" :items-per-page="40">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Users</v-toolbar-title><br />
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
              hide-details></v-text-field>
          </v-toolbar>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="viewCard(item)">
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!--Dialog---->
    <v-dialog width="50%" v-model="dialog" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="dark">
          <v-btn icon dark @click="cleanData()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>User Id: {{ userData.Id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="!userData.Ban" dark text color="error" @click="disableUser()">
              Disable user
            </v-btn>
            <v-btn v-if="userData.Ban" dark text color="success" @click="enableUser()">
              Enable user
            </v-btn>
            <v-btn dark text @click="saveData()">
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

              <!--General info-->
        <v-subheader>General info</v-subheader>
        <v-simple-table dense>
          <template>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Field
                </th>
                <th class="text-left">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ban</td>
                <td>Ban</td>
                <td><span v-if="userData.Ban"> {{ userData.Ban }}</span> <span style="color:gray" v-else> No ban</span>
                </td>
              </tr>
              <tr>
                <td>Register date</td>
                <td>RegisterDate</td>
                <td>{{ userData.RegisterDate }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>Name</td>
                <td><span v-if="userData.Name"> {{ userData.Name }}</span> <span style="color:gray" v-else> No info</span>
                </td>
              </tr>
              <tr>
                <td>Surname</td>
                <td>Surname</td>
                <td><span v-if="userData.Surname"> {{ userData.Surname }}</span> <span style="color:gray" v-else> No
                    info</span></td>
              </tr>
              <tr>
                <td>Birth Date</td>
                <td>BirthDate</td>
                <td><span v-if="userData.BirthDate"> {{ userData.BirthDate }}</span> <span style="color:gray" v-else> No
                    info</span></td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>Gender</td>
                <td><span v-if="userData.Gender"> {{ userData.Gender }}</span> <span style="color:gray" v-else> No
                    info</span></td>
              </tr>
              <tr>
                <td>Email</td>
                <td>Email</td>
                <td><span v-if="userData.Email"> {{ userData.Email }}</span> <span style="color:gray" v-else> No
                    info</span></td>
              </tr>
              <tr>
                <td>About</td>
                <td>About</td>
                <td><span v-if="userData.About"> {{ userData.About }}</span> <span style="color:gray" v-else> No
                    info</span></td>
              </tr>
              <tr>
                <td>Coach</td>
                <td>IsCoach</td>
                <td><span v-if="userData.IsCoach">YES </span> <span
                    style="color:gray" v-else> No</span></td>
              </tr>
              <tr>
                <td>Coach contacts</td>
                <td>CoachInfo.Contacts</td>
                <td><span v-if="userDataCoachInfo.Contacts"> {{ userDataCoachInfo.Contacts }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Coach portfolio</td>
                <td>CoachInfo.Portfolio</td>
                <td><span v-if="userDataCoachInfo.Portfolio"> {{ userDataCoachInfo.Portfolio }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>About coach</td>
                <td>CoachInfo.Comment</td>
                <td><span v-if="userDataCoachInfo.Comment"> {{ userDataCoachInfo.Comment }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <!-- TENNIS-->
              <v-subheader>Tennis info</v-subheader>
              <tr>
                <td>NTRP registered</td>
                <td>...Tennis.NtrpRegistered</td>
                <td><span v-if="userDataTennis.NtrpRegistered"> {{ userDataTennis.NtrpRegistered }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>NTRP average</td>
                <td>...Tennis.NtrpAverage</td>
                <td><span v-if="userDataTennis.NtrpAverage"> {{ userDataTennis.NtrpAverage }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Tennis ranking</td>
                <td>...Tennis.TennisRanking</td>
                <td><span v-if="userDataTennis.TennisRanking"> {{ userDataTennis.TennisRanking }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Matches</td>
                <td>...Tennis.Matches</td>
                <td><span v-if="userDataTennis.Matches"> {{ userDataTennis.Matches }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Wins</td>
                <td>...Tennis.Wins</td>
                <td><span v-if="userDataTennis.Wins"> {{ userDataTennis.Wins }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>2vs2 matches</td>
                <td>...Tennis.DoubleMatches</td>
                <td><span v-if="userDataTennis.DoubleMatches"> {{ userDataTennis.DoubleMatches }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>2vs2 wins</td>
                <td>...Tennis.DoubleWins</td>
                <td><span v-if="userDataTennis.DoubleWins"> {{ userDataTennis.DoubleWins }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Review rating</td>
                <td>...Tennis.ReviewRating</td>
                <td><span v-if="userDataTennis.ReviewRating"> {{ userDataTennis.ReviewRating }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Review numbers</td>
                <td>...Tennis.ReviewNumbers</td>
                <td><span v-if="userDataTennis.ReviewNumbers"> {{ userDataTennis.ReviewNumbers }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <!--PADEL TENNIS-->
              <v-subheader>Padel Tennis info</v-subheader>
              <tr>
                <td>NTRP registered</td>
                <td>...PaddleTennis.NtrpRegistered</td>
                <td><span v-if="userDataPaddleTennis.NtrpRegistered"> {{ userDataPaddleTennis.NtrpRegistered }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>NTRP average</td>
                <td>...PaddleTennis.NtrpAverage</td>
                <td><span v-if="userDataPaddleTennis.NtrpAverage"> {{ userDataPaddleTennis.NtrpAverage }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Tennis ranking</td>
                <td>...PaddleTennis.TennisRanking</td>
                <td><span v-if="userDataPaddleTennis.TennisRanking"> {{ userDataPaddleTennis.TennisRanking }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Matches</td>
                <td>...PaddleTennis.Matches</td>
                <td><span v-if="userDataPaddleTennis.Matches"> {{ userDataPaddleTennis.Matches }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Wins</td>
                <td>...PaddleTennis.Wins</td>
                <td><span v-if="userDataPaddleTennis.Wins"> {{ userDataPaddleTennis.Wins }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>2vs2 matches</td>
                <td>...PaddleTennis.DoubleMatches</td>
                <td><span v-if="userDataPaddleTennis.DoubleMatches"> {{ userDataPaddleTennis.DoubleMatches }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>2vs2 wins</td>
                <td>...PaddleTennis.DoubleWins</td>
                <td><span v-if="userDataPaddleTennis.DoubleWins"> {{ userDataPaddleTennis.DoubleWins }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Review rating</td>
                <td>...PaddleTennis.ReviewRating</td>
                <td><span v-if="userDataPaddleTennis.ReviewRating"> {{ userDataPaddleTennis.ReviewRating }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Review numbers</td>
                <td>...PaddleTennis.ReviewNumbers</td>
                <td><span v-if="userDataPaddleTennis.ReviewNumbers"> {{ userDataPaddleTennis.ReviewNumbers }}</span> <span
                    style="color:gray" v-else> No info</span></td>
              </tr>


              <v-subheader>Other info</v-subheader>
              <tr>
                <td>Coins</td>
                <td>Coins</td>
                <td><span v-if="userData.Coins"> {{ userData.Coins }}</span> <span style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Achievements</td>
                <td>Achievements</td>
                <td><span v-if="userData.Achievements"> {{ userData.Achievements }}</span> <span style="color:gray" v-else> No info</span></td>
              </tr>
              <tr>
                <td>Reviews</td>
                <td>Reviews</td>
                <td><span v-if="userData.Reviews"> {{ userData.Reviews }}</span> <span style="color:gray" v-else> No info</span></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider></v-divider>

      </v-card>
    </v-dialog>

  </v-row>
</template>
  
<script>
/* eslint-disable */

import { getUsers, updateUserData } from '../services/user-service'

export default {
  name: 'UsersTable',
  data: () => ({
    userData: [],
    userDataCoachInfo: {},
    userDataTennis: {},
    userDataPaddleTennis: {},
    search: '',
    dialog: false,
    headers: [
      {
        width: "200px",
        text: "ID",
        align: "start",
        sortable: false,
        value: "Id",
      },
      {
        width: "120px",
        text: "Country",
        align: "start",
        sortable: false,
        value: "Country",
      },
      {
        text: "E-mail",
        align: "start",
        sortable: false,
        value: "Email",
      },
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "Name",
      },
      {
        text: "IsCoach",
        align: "start",
        sortable: false,
        value: "IsCoach",
      },
      {
        text: "Ban",
        align: "start",
        sortable: false,
        value: "Ban",
      },
      { text: 'Actions', value: 'actions' },
    ],
    users: [],
  }),
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      getUsers().then((res) => {
      console.log(res)
      this.users = res
    })
    },
    viewCard(item) {
      console.log("item", item)
      this.userData = item
      if (!item.CoachInfo) this.userDataCoachInfo = {}; else this.userDataCoachInfo = item.CoachInfo
      let SportsStats = {}
      if (!item.SportsStats) SportsStats = {}; else SportsStats = item.SportsStats
      if (!SportsStats.Tennis) this.userDataTennis = {}; else this.userDataTennis = SportsStats.Tennis
      if (!SportsStats.PaddleTennis) this.userDataPaddleTennis = {}; else this.userDataPaddleTennis = SportsStats.PaddleTennis
      this.dialog = true
    },
    cleanData(){
      this.userData = {}
      this.userDataTennis = {}
      this.userDataPaddleTennis = {}
      this.userDataCoachInfo = {}
      this.dialog = false
      this.getData()
    },
    saveData(){
      let changedData = this.userData
      this.cleanData()
    },
    async disableUser(){
      let newUserData = this.userData
      newUserData.Ban = true
      await updateUserData(newUserData).then((res) => {
      console.log("res",res)
      this.cleanData()
      })
    },
    async enableUser(){
      let newUserData = this.userData
      newUserData.Ban = false
      await updateUserData(newUserData).then((res) => {
      console.log("res",res)
      this.cleanData()
      })
    }
  }
};
</script>
