<template>
    <div>
      <UserReportsTable/>
    </div>
</template>
  
  <script>
  /* eslint-disable */

  import UserReportsTable from '../components/UserReportsTable.vue'

  export default {
    components: {UserReportsTable},
    name: 'UserReports',
    data: () => ({
      //
    }),
  };
  </script>
  