<template>
    <UsersTable/>
</template>
  
  <script>
  import UsersTable from '../components/UsersTable.vue'
  /* eslint-disable */
  export default {
    components: {UsersTable},
    name: 'Users',
    data: () => ({
      //
    }),
  };
  </script>
  