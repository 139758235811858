import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import VueRouter from 'vue-router'
import router from './router'

import * as firebase from '@/firebase'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

const auth = getAuth();

Vue.config.productionTip = false

Vue.use(VueRouter)

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyCO9CNVJEzjlL-rHU5OyAU7z-x2FrAu5JY', // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  language: 'En', // Optional
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
  vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
});

onAuthStateChanged(auth, () => {
  new Vue({
    vuetify,
    router,
    firebase,
    render: h => h(App)
  }).$mount('#app')
});
