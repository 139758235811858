import { collection, getDocs } from "firebase/firestore";
import { getStorage, ref, listAll } from "firebase/storage";
import { db } from '@/firebase'

async function getComplaints() {
    let complaintsList = []
    const querySnapshot = await getDocs(collection(db, "reports"));
    querySnapshot.forEach((doc) => {
        let complaint = doc.data()
        complaint.Id = doc.id
        complaintsList.push(complaint)
    })
    return complaintsList
}

let matchesFolders = []
const storage = getStorage();

async function getPhotosList(dataId) {
    matchesFolders = []
    //console.log("dataId", dataId)
    //const refUserId = 'CmGE2tHmtyctwIklsVGUYkFe7Ck1'
    const listRef = ref(storage, 'Images/Matches');
    await listAll(listRef)
        .then((res) => {
            res.prefixes.forEach((folderRef) => {
                matchesFolders.push(folderRef.name)
            });
        }).catch((error) => {
            // Uh-oh, an error occurred!
            console.log("error", error)
        });
        const userPhotos = await GetImagesList(dataId)
        console.log("s userPhotos", userPhotos)
     return userPhotos 
}

async function GetImagesList(dataId) {
    const refUserId = dataId
    let userPhotos = []
    for (let index = 0; index < matchesFolders.length; index++) {
        const listRef = ref(storage, 'Images/Matches/' + matchesFolders[index]);
        await listAll(listRef)
            .then((res) => {
                res.items.forEach((itemRef) => {
                    const userId = itemRef.name.split('_')[1]
                    if (refUserId === userId) {
                        //console.log("itemRef.path", itemRef.fullPath)
                        userPhotos.push(itemRef.fullPath)
                    }
                });
            })
    }
    console.log("send")
    return userPhotos
}


export {
    getComplaints,
    getPhotosList
}