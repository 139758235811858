<template>
    <v-row style="margin-left:50px; margin-top: 50px; justify-content: center;">
        <v-card width="95%" elevation="5" outlined style="border-radius:20px">
            <div style="margin-top:15px" />
            <v-data-table :search="search" :headers="headers" :items="items" :items-per-page="40">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>User complaints</v-toolbar-title><br />
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="viewCard(item.CheaterId)">
                        mdi-eye
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <!--Dialog---->
        <v-dialog width="50%" v-model="dialog" persistent transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="dark">
                    <v-btn icon dark @click="cleanData()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>User Id: {{ userData.Id }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text color="error" @click="disableUser()">
                            Disable user
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-subheader>General info</v-subheader>
                <div>
                    <v-simple-table dense>
                        <template>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Name
                                    </th>
                                    <th class="text-left">
                                        Value
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Ban</td>
                                    <td><span v-if="userData.Ban"> {{ userData.Ban }}</span> <span style="color:gray"
                                            v-else> No ban</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td><span v-if="userData.Name"> {{ userData.Name }}</span> <span style="color:gray"
                                            v-else> No info</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Surname</td>
                                    <td><span v-if="userData.Surname"> {{ userData.Surname }}</span> <span
                                            style="color:gray" v-else> No
                                            info</span></td>
                                </tr>
                                <tr>
                                    <td>Gender</td>
                                    <td><span v-if="userData.Gender"> {{ userData.Gender }}</span> <span style="color:gray"
                                            v-else> No
                                            info</span></td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td><span v-if="userData.Email"> {{ userData.Email }}</span> <span style="color:gray"
                                            v-else> No
                                            info</span></td>
                                </tr>
                                <tr>
                                    <td>About</td>
                                    <td><span v-if="userData.About"> {{ userData.About }}</span> <span style="color:gray"
                                            v-else> No
                                            info</span></td>
                                </tr>
                                <tr>
                                    <td>Coach</td>
                                    <td><span v-if="userData.IsCoach">YES </span> <span style="color:gray" v-else> No</span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
                <v-subheader>User photos</v-subheader>
                <v-row>
                    <v-col cols="6" md="4" v-for="n in imageSource.length" :key="n">
                        {{ n }}
                        <v-img :src=imageSource[n-1] />
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>


    </v-row>
</template>
<script>
/* eslint-disable */

import moment from "moment";
import {
    getComplaints, getPhotosList
} from '../services/complaints-service'
import { getUsers, updateUserData } from '../services/user-service'

export default {
    name: 'UserReports',
    data: () => ({
        dialog: false,
        items: [],
        users: [],
        photos: [],
        imageSource: [],
        userData: [],
        headers: [
            {

                width: 200,
                text: "Complaint ID",
                align: "start",
                sortable: true,
                value: "Id",
            },
            {
                text: "Complaint TO Id",
                align: "start",
                sortable: true,
                value: "CheaterId",
            },
            {
                text: "Complaint FROM",
                align: "start",
                sortable: true,
                value: "SenderId",
            },
            {
                width: 200,
                text: "Date",
                align: "start",
                sortable: true,
                value: "Date",
            },
            {
                text: "ReportTypes",
                align: "start",
                sortable: false,
                value: "ReportTypes",
            },
            {
                text: "Description",
                align: "start",
                sortable: false,
                value: "Description",
            },
            {
                text: 'Actions',
                align: "start",
                sortable: false, 
                value: 'actions'
            },
        ],
        search: '',
    }),
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            getComplaints().then((res) => {
                for (let index = 0; index < res.length; index++) {
                    const isuueDate = new Date(res[index].Date.seconds * 1000 + res[index].Date.nanoseconds / 1000000)
                    //console.log("Date:", isuueDate)
                    res[index].Date = moment(isuueDate).format('LLL');
                }
                this.items = res
                getUsers().then((res) => {
                    this.users = res
                })
            })
        },

        async viewCard(id) {
            console.log("Complaint ID", id)
            // for (let index = 0; index < this.users.length; index++) {
            //     if (this.users[index].Id == id) {
            //         this.userData = this.users[index]
            //     }
            // }
            for (let index = 0; index < this.users.length; index++) {
                if (this.users[index].Id == id) {
                    this.userData = this.users[index]
                }
            }
            await getPhotosList(id).then((res) => {

                console.log("res", res)
                this.photos = res
                console.log("this.photos", this.photos)
                console.log("this.photos.lendth", this.photos.length)
                for (let index = 0; index < this.photos.length; index++) {
                    //https://firebasestorage.googleapis.com/v0/b/tennis-948b2.appspot.com/o/Images%2FClubs%2FOc8OCabkxD%2FImage0?alt=media
                    //https://firebasestorage.googleapis.com/v0/b/tennis-948b2.appspot.com/o/Images%2FMatches%2F-Non8nzPFMe3vmdbA87Q%2F-NooZUh-V0a6JypDgEHg_CmGE2tHmtyctwIklsVGUYkFe7Ck1?alt=media
                    const str = this.photos[index].split('/').join('%2F');
                    const fullAddress = "https://firebasestorage.googleapis.com/v0/b/tennis-948b2.appspot.com/o/" + str + "?alt=media"
                    this.imageSource.push(fullAddress)
                }
                console.log("this.photos.lendth", this.photos.length)
                this.dialog = true
            })
        },

        getUserData(id) {
            for (let index = 0; index < this.users.length; index++) {
                if (this.users[index].Id == id) {
                    this.userData = this.users[index]
                }
            }
        },

        cleanData() {
            this.items = []
            this.users = []
            this.imageSource = []
            this.photos = []
            this.userData = []
            this.dialog = false
            this.getData()
        },

        async disableUser() {
            let newUserData = this.userData
            newUserData.Ban = true
            await updateUserData(newUserData).then((res) => {
                console.log("res", res)
                this.cleanData()
            })
        },

        //end
    }
}
</script>
