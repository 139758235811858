import { collection, getDocs, doc, updateDoc } from "firebase/firestore"; 
import {db} from '@/firebase'

async function getUsers() {
    let usersList = []
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach((doc) => {   
        let user = doc.data()
        user.Id = doc.id
        usersList.push(user)
    })
    return usersList
}

async function updateUserData(UserData) {
    try {
        const userRef = doc(db, "users", UserData.Id)
        const updatedObject = { ...UserData}
        await updateDoc(userRef, updatedObject);
        return { status: "success" }
    } catch (e) {
        console.log(e)
        return e
    }
}



export {
    getUsers,
    updateUserData
}