import { collection, getDocs, doc, updateDoc, addDoc } from "firebase/firestore"; 
import { getStorage, ref, uploadBytes } from "firebase/storage";
import {db} from '@/firebase'

async function getNews() {
    let newsList = []
    const querySnapshot = await getDocs(collection(db, "news"));
    querySnapshot.forEach((doc) => {   
        let news = doc.data()
        news.Id = doc.id
        newsList.push(news)
    })
    return newsList
}

async function updateNews(NewsData) {
    try {
        const newsRef = doc(db, "news", NewsData.Id)
        const updatedObject = { ...NewsData}
        await updateDoc(newsRef, updatedObject);
        return { status: "success" }
    } catch (e) {
        console.log(e)
        return e
    }
}

async function addNews(NewsData) {
    try {
        const updatedObject = { ...NewsData}
        const docRef = await addDoc(collection(db, "news"), {...updatedObject})
        const docRefId = docRef.id
        return { docRefId }
    } catch (e) {
        console.log(e)
        return e
    }
}

async function uploadImage(file, Id){
    try {
        let ImagesData = []
        const storage = getStorage()
        const folderId = Id
        console.log("folderId", folderId)

        for (let index = 0; index < file.length; index++) {
            const element = file[index]
            const path = 'Images/News/'+folderId+'/Image'+ index
        const storageRef = ref(storage, path)
        await uploadBytes(storageRef, element).then((snapshot) => {
            //console.log('Uploaded a blob or file!')
            console.log('snapshot: ',snapshot)
            let metaData = {}
            metaData.Path = snapshot.metadata.fullPath
            metaData.Bucket = snapshot.metadata.bucket
            metaData.Type = snapshot.metadata.contentType
            metaData.Name = snapshot.metadata.name
            ImagesData[index] = metaData
            return { status: "success" }
          })
        }
        return {ImagesData}
    } catch (e) {
        console.log(e)
        return e
    }
}


export {
    getNews,
    updateNews,
    addNews,
    uploadImage
}