<template>
    <v-row justify="center">
    <v-card style="margin-top:150px" curved>
      <v-card-title>
        <span >Sign in to you account</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="E-mail"
          name="e-mail"
          v-model="loginData.email"
        />
        <v-text-field
          type="password"
          label="Your password"
          name="password"
          ref="password"
          v-model="loginData.password"
        />
          <v-btn
            dark
            @click="checkLoginData()"
          >Sign In</v-btn>
      </v-card-text>
    </v-card>
  </v-row>
</template>
<script>
import { signIn } from '../services/auth-service'
  /* eslint-disable */
  export default {
    name: 'SignIn',
    data: () => ({
      email: null,
      password: null,
      loginData: []
      //
    }),
    methods: {
      async checkLoginData(){
        await signIn(this.loginData).then((res) => {
          console.log("got user data: ", res)
          this.$router.push({
                name: "Users"
              })
        })
      }
    }

  };
  </script>
  
