// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFirestore } from 'firebase/firestore'
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCO9CNVJEzjlL-rHU5OyAU7z-x2FrAu5JY",
  authDomain: "tennis-948b2.firebaseapp.com",
  projectId: "tennis-948b2",
  storageBucket: "tennis-948b2.appspot.com",
  messagingSenderId: "1077756126520",
  appId: "1:1077756126520:web:47885948eb099f7e1c8dcd",
  measurementId: "G-PZPTZFWGQZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app);
const functions = getFunctions(app);

export {
    db,
    analytics,
    auth,
    storage,
    functions
  }