<template>
    <v-row style="margin-left:50px; margin-top: 50px; justify-content: center;">
        <v-card width="95%" elevation="5" outlined style="border-radius:20px">
            <div style="margin-top:15px" />
            <v-data-table :search="search" :headers="headers" :items="items" :items-per-page="40">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Clubs</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn outlined style="margin-right:20px" @click="openNewClubDialog()">
                            <v-icon>mdi-plus</v-icon> Add new
                        </v-btn>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="viewCard(item)">
                        mdi-eye
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
        <!--Uploading-->
        <v-dialog v-model="upLoading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!--Dialog---->
        <v-dialog width="80%" v-model="dialog" persistent transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="dark">
                    <v-btn icon dark @click="cleanData()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-toolbar-items>
                        <v-btn v-if="isNewClub" dark text @click="addClub()">
                            Add
                        </v-btn>
                        <v-btn v-if="!isNewClub" dark text @click="saveClub()">
                            Save
                        </v-btn>
                    </v-toolbar-items> -->
                </v-toolbar>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <div style="margin:10px">
                        <v-subheader>General info</v-subheader>
                        <v-row>
                            <v-col cols="6" md="4">
                                <v-select dense :items="sports" label="Sport" outlined v-model="itemData.Sport"
                                    :rules="[rules.required]"></v-select>
                                <v-text-field dense label="Name" outlined v-model="itemData.Name"
                                    :rules="[rules.required]"></v-text-field>
                                <v-text-field dense label="Price range" outlined v-model="itemData.PriceRange"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-select dense :items="countries" item-text="Name" item-value="Id" label="Country" outlined
                                    v-model="itemData.CountryId" @change="changeCountry()"
                                    :rules="[rules.required]"></v-select>
                                <v-text-field dense label="Working days" outlined v-model="itemData.WorkingDays"
                                    :rules="[rules.required]"></v-text-field>
                                <v-select dense :items="status" label="Visability status" outlined v-model="itemData.Status"
                                    :rules="[rules.required]"></v-select>
                            </v-col>
                            <v-col cols="6" md="4">
                                <!-- <v-text-field dense label="Location" outlined disabled v-model="LocationData.FullName"></v-text-field> -->
                                <v-select dense :items="localRegions" item-text="Name" item-value="Id" label="Region"
                                    outlined v-model="itemData.RegionId" :rules="[rules.required]"></v-select>
                                <vuetify-google-autocomplete v-on:placechanged="getAddressData" label="Location (Map)"
                                    id="map" outlined dense v-model="LocationData.FullName" />
                                <v-text-field dense label="Address (Public field)" outlined v-model="itemData.PublicAddress"
                                    :rules="[rules.required]"></v-text-field>
                                <v-text-field dense label="Working hours" outlined v-model="itemData.WorkingHours"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-subheader style="margin-top:-20px">Courts</v-subheader>
                        <v-row>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="Grass courts" type="number" outlined
                                    v-model="itemData.GrassCourts" :rules="[rules.required]"></v-text-field>
                                <v-text-field dense label="Clay courts" type="number" outlined v-model="itemData.ClayCourts"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="Hard courts" type="number" outlined v-model="itemData.HardCourts"
                                    :rules="[rules.required]"></v-text-field>
                                <v-text-field dense label="Artificial grass courts" type="number" outlined
                                    v-model="itemData.ArtificialGrassCourts" :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="Indoor courts" type="number" outlined
                                    v-model="itemData.IndoorCours" :rules="[rules.required]"></v-text-field>
                                <v-text-field dense label="Outdoor courts" type="number" outlined
                                    v-model="itemData.OutdoorCourts" :rules="[rules.required]"></v-text-field>
                            </v-col>
                        </v-row>


                        <v-subheader style="margin-top:-20px">Facilities</v-subheader>
                        <v-row>
                            <v-col cols="6" md="4">
                                <v-select dense :items="boolOption" label="Coach" outlined
                                    v-model="itemData.Coach"></v-select>
                                <v-select dense :items="boolOption" label="Dressing room" outlined
                                    v-model="itemData.DressingRoom"></v-select>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-select dense :items="boolOption" label="Shower" outlined
                                    v-model="itemData.Shower"></v-select>
                                <v-select dense :items="boolOption" label="Stuff rental" outlined
                                    v-model="itemData.StuffRental"></v-select>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-select dense :items="boolOption" label="Lockers" outlined
                                    v-model="itemData.Lockers"></v-select>
                            </v-col>
                        </v-row>


                        <v-subheader style="margin-top:-20px">Contacts </v-subheader>
                        <v-row>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="Phone" outlined v-model="itemData.Phone"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="E-mail" outlined v-model="itemData.Email"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="Telegram" outlined v-model="itemData.Telegram"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="Whatsapp" outlined v-model="itemData.Whatsapp"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="Instagram" outlined v-model="itemData.Instagram"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="Facebook" outlined v-model="itemData.Facebook"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="Vk" outlined v-model="itemData.Vk"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field dense label="Website" outlined v-model="itemData.Website"></v-text-field>
                            </v-col>
                        </v-row>


                        <v-subheader v-if="isNewClub" style="margin-top:-20px">Photos </v-subheader>
                        <v-subheader v-if="!isNewClub" style="margin-top:-20px">Replace Current images with
                            new:</v-subheader>
                        <div v-for="(fileToUpload, index) in filesToUpload" :key="index">
                            <v-file-input label="Image file" key="fileToUpload" v-model="filesToUpload[index]" outlined
                                dense :rules="imagesUploadRules" accept="image/jpeg/png"></v-file-input>
                        </div>
                        <div style="margin-top:-20px">
                            <v-btn v-if="this.filesToUpload.length < 6" style="float: right;" dark dense x-small fab
                                elevation="0" color="success" @click="addFileInput()">
                                <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn v-if="this.filesToUpload.length > 1" style="float: right; margin-right: 10px" fab dark
                                outlined x-small color="error" @click="removeFileInput()">
                                <v-icon dark>mdi-minus</v-icon>
                            </v-btn>
                        </div>
                        <v-btn style="margin-top:40px; margin-bottom: 20px;" @click="uploadFile()">Upload photos</v-btn>
                        <div v-if="!isNewClub">
                            <v-subheader style="margin-top:0px">Current images preview</v-subheader>
                            <v-row>
                                <v-col cols="6" md="4" v-for="n in imageSource.length" :key="n">
                                    {{ n }}
                                    <v-img :src=imageSource[n-1] />
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div style="margin-top:20px">
                        <center>
                            <v-btn style="width:100%" v-if="isNewClub" color="success" @click="addClub()">
                                Add new Club
                            </v-btn>
                            <v-btn style="width:100%" v-if="!isNewClub" color="warning" @click="saveClub()">
                                Save changes
                            </v-btn>
                        </center>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
/* eslint-disable */

import { nanoid } from 'nanoid'
import {
    getGlubs,
    updateClub,
    addClub,
    uploadImage
} from '../services/club-service'




import {
    getRegions,
    getCountries,
} from '../services/region-service'

import { divideWord } from '../services/utils'
import { TranslitToLat } from '../services/translit-service'

export default {
    components: {},
    name: 'ClubsTable',
    data: () => ({
        valid: true,
        rules: {
            required: value => !!value || 'Required.',
        },
        upLoading: false,
        imageSource: [],
        imagesUploadRules: [
            value => !value || value.size < 1000000 || 'Image size should be less than 1 MB!',
        ],
        NumberOfImages: 0,
        filesToUpload: [undefined],
        LocationData: {},
        fullAddress: '',
        address: '',
        boolOption: ['Yes', 'No'],
        status: ['Visible', 'Hidden'],
        sports: ['Tennis', 'Paddle Tennis'],
        // countries: ['Latvia', 'Spain', 'Russia'],
        isNewClub: false,
        dialogTitle: null,
        dialog: false,
        itemData: [],
        items: [],
        countries: [],
        regions: [],
        localRegions: [],
        headers: [
            {
                width: "200px",
                text: "ID",
                align: "start",
                sortable: false,
                value: "Id",
            },
            {
                text: "Country",
                align: "start",
                sortable: false,
                value: "Country",
            },
            {
                text: "Region",
                align: "start",
                sortable: false,
                value: "Region",
            },
            {
                text: "Sport",
                align: "start",
                sortable: false,
                value: "Sport",
            },
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "Name",
            },
            {
                text: "Location",
                align: "start",
                sortable: false,
                value: "LocationData.FullName",
            },
            {
                text: "Status",
                align: "start",
                sortable: false,
                value: "Status",
            },
            { text: 'Actions', value: 'actions' },
        ],
        search: '',
    }),
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            getGlubs().then((res) => {
                //console.log(res)
                this.items = res
            })

            getCountries().then((res) => {
                res.forEach(element => {
                    this.countries.push(element)
                });
                //this.countries = res
            })

            getRegions().then((res) => {
                res.forEach(element => {
                    this.regions.push(element)
                });
            })

        },

        getAddressData: function (addressData, placeResultData, id) {
            this.address = addressData;
            this.LocationData.Latitude = addressData.latitude
            this.LocationData.Longitude = addressData.longitude
            this.LocationData.Name = addressData.name
            this.LocationData.PlaceId = addressData.place_id
            //console.log("this.address", this.address)
            //console.log("this.LocationData", this.LocationData)
        },

        cleanData() {
            this.filesToUpload = [undefined]
            this.itemData = []
            this.LocationData = {}
            this.address = ''
            this.fullAddress = ''
            this.dialogTitle = null
            this.isNewClub = false
            this.imageSource = [undefined]
            this.upLoading = false
            this.items = []
            this.countries = []
            this.localRegions = []
            this.regions = []
            this.dialog = false
            this.getData()
        },
        openNewClubDialog() {
            this.dialogTitle = "Add a new club"
            this.isNewClub = true
            this.dialog = true
        },
        viewCard(item) {
            console.log("item", item)
            this.itemData = item
            if (this.itemData.CountryId) {
                this.changeCountry()
            }
            this.LocationData = item.LocationData
            this.dialogTitle = "Club Id: " + this.itemData.Id
            this.isNewClub = false
            this.dialog = true
            if (this.itemData.Images && this.itemData.Images.length > 0) {
                for (let index = 0; index < this.itemData.Images.length; index++) {
                    //https://firebasestorage.googleapis.com/v0/b/tennis-948b2.appspot.com/o/Images%2FClubs%2FOc8OCabkxD%2FImage0?alt=media
                    this.imageSource[index] = "https://firebasestorage.googleapis.com/v0/b/tennis-948b2.appspot.com/o/Images%2FClubs%2F" + this.itemData.ImagesFolderId + "%2FImage" + [index] + "?alt=media"
                    this.NumberOfImages++
                }
            }

        },
        async addClub() {
            const validationResult = this.$refs.form.validate();
            const isValid = validationResult;
            if (isValid) {
                this.upLoading = true
                this.localRegions.forEach(element => {
                    if (element.CountryId === this.itemData.CountryId) {
                        this.itemData.Country = element.Country
                    }
                });

                this.localRegions.forEach(element => {
                    if (element.Id === this.itemData.RegionId) {
                        this.itemData.Region = element.Name
                    }
                });
                let newData = this.itemData
                const translitName = TranslitToLat(newData.Name)
                newData.SearchSyllables = divideWord(translitName)
                newData.LocationData = this.LocationData
                console.log("lowerCase", this.itemData.Name.toLowerCase())
                newData.NameLowerCase = this.itemData.Name.toLowerCase()
                // newData.CourtNumber = parseFloat(newData.GrassCourts) + parseFloat(newData.ClayCourts) + parseFloat(newData.HardCourts) + parseFloat(newData.ArtificialGrassCourts)
                await addClub(newData).then((res) => {
                    console.log("New Club has been ADDED", res)
                    this.cleanData()
                })
            }
        },
        async saveClub() {
            const validationResult = this.$refs.form.validate();
            const isValid = validationResult;
            if (isValid) {
                this.upLoading = true
                this.localRegions.forEach(element => {
                    if (element.CountryId === this.itemData.CountryId) {
                        this.itemData.Country = element.Country
                    }
                });

                this.localRegions.forEach(element => {
                    if (element.Id === this.itemData.RegionId) {
                        this.itemData.Region = element.Name
                    }
                });
                let newData = this.itemData
                const translitName = TranslitToLat(newData.Name)
                newData.SearchSyllables = divideWord(translitName)
                newData.LocationData = this.LocationData
                newData.CourtNumber = parseFloat(newData.GrassCourts) + parseFloat(newData.ClayCourts) + parseFloat(newData.HardCourts) + parseFloat(newData.ArtificialGrassCourts)
                await updateClub(newData).then((res) => {
                    console.log("Club database UPDATED! ", res)
                    this.cleanData()
                })
            }
        },
        uploadFile() {
            this.upLoading = true
            if (!this.itemData.ImagesFolderId) this.itemData.ImagesFolderId = nanoid(10)
            uploadImage(this.filesToUpload, this.itemData.ImagesFolderId).then((res) => {
                this.itemData.Images = res.ImagesData
                console.log("Images UPLOADED to server ", this.itemData.Images)
                //if not New Club
                if (this.isNewClub == false) {
                    this.saveClub()
                } else this.upLoading = false
            })
        },
        addFileInput() {
            if (this.filesToUpload.length < 6) {
                this.filesToUpload.push(undefined)
            }
        },
        removeFileInput() {
            if (this.filesToUpload.length > 1) {
                this.filesToUpload.pop();
            }
        },
        changeCountry() {
            this.localRegions = []
            this.regions.forEach(element => {
                if (element.CountryId === this.itemData.CountryId) {
                    this.localRegions.push(element)
                }
            });
        }
    }
}
</script>
