<template>
  <v-container>
    <section id="Desktop" v-if="!isMobile()">
      <section id="TopScreen" class="TopScreen">
        <v-row>
          <v-col class="TopScreenLeft">

            <div style="margin-top: 80px;" />
            <!-- <div class="iconContainer"> -->
              <v-img src="../assets/logoText.png" width="50%" style="margin: auto;" />
            <!-- </div> -->
            <div style="margin-top: 20px;" />
            <!-- <H1 class="HeadingText"><span style="color: black;">Play</span><span
                style="color: #ABCC25; margin-left: -15px;">On</span></H1> -->
                <center>
                  <H3 class="subHeadingText" style="max-width: 250px; text-align:center;">The ultimate sport player network</H3>
                </center>

            
    <div class="storeButtonsContainer">
      <!-- <span class="storeText">Download now</span> -->
        <a href="https://apps.apple.com/pl/app/id6445921680"> <v-img src="../assets/appleBadge.png" width="200px" /> </a>
      <a href="https://play.google.com/store/apps/details?id=com.simbico.tennis"> <v-img src="../assets/googleBadge.png"
          width="220px" /> </a>
    </div>

          </v-col>
          <v-col class="TopScreenRight">
            <v-img class="mapImage" src="../assets/mapImage.png" />
            <!-- <v-img class="phoneImage" src="../assets/phoneImage.png" /> -->
          </v-col>
        </v-row>
        <v-img class="phoneImage" src="../assets/phoneImage.png" />
      </section>

      <section id="separatorAbout" class="separatorAbout">
        <v-row style="margin-top: -70px">
          <v-col cols="2">
            <H3 class="subHeadingText">About</H3>
          </v-col>
          <v-col>
            <span class="text">Play On is the perfect mobile app for tennis players who want to stay fit and have fun at
              the same time. With Play On, you can connect with players all over the world, regardless of skill level or
              location. Exchange messages and coordinate matches with ease. The app even offers interactive scoreboards
              and match statistics to track progress and competition. <br /> <br /> Whether you’re looking for a workout
              partner or to
              find your next big tournament opponent, Play On is the perfect tool to help get you connected. Get your game
              on and start playing today!</span>
          </v-col>
        </v-row>
      </section>

      <section id="problems" class="problems">
        <center>
          <span class="problemsSubheader">our <span style="color: #ABCC25;">Features</span></span>
        </center>

        <v-row style="margin-top:20px">
          <v-col class="problemContainer">
            <h5 class="problemHeaderText">01</h5>
            <span class="text">
              Find tennis partner or court during a business trip in another city
            </span>
          </v-col>

          <v-col class="problemContainer">
            <h5 class="problemHeaderText">02</h5>
            <span class="text">
              Play with different opponents, score and collect results
            </span>
          </v-col>

          <v-col class="problemContainer">
            <h5 class="problemHeaderText">03</h5>
            <span class="text">
              Find new acquaintances with similar interests and become friends
            </span>
          </v-col>
        </v-row>
      </section>


      <section id="links" class="linksSection d-flex justify-center mb-6 bg-surface-variant">
        <router-link to="/SignIn" class="link">
          <span>SignIn</span>
        </router-link>
        <router-link to="/PrivacyPolicy" class="link">
          <span>Privacy Policy</span>
        </router-link>
        <router-link to="/ToU" class="link">
          <span>Terms of Use</span>
        </router-link>
      </section>
    </section>

    <section id="Mobile" v-if="isMobile()">
      <section id="TopScreen" class="TopScreen">
        <v-row>
          <v-col class="TopScreenLeft">
            <div style="margin-top: 100px;" />
              <v-img src="../assets/logoText.png" width="50%"  style="margin: auto;"/>
            <div style="margin-top: 30px;" />
                <center>
                  <H3 class="subHeadingText" style="max-width: 250px; text-align:center;">The ultimate sport player network</H3>
                </center>

    <div class="storeButtonsContainerMob">
      <!-- <span class="storeText">Download now</span> -->
        <a href="https://apps.apple.com/pl/app/id6445921680"> <v-img src="../assets/appleBadge.png" width="200px" /> </a>
      <a href="https://play.google.com/store/apps/details?id=com.simbico.tennis"> <v-img src="../assets/googleBadge.png"
          width="220px" /> </a>
    </div>
          </v-col>
        </v-row>
        <!-- <v-img class="phoneImage" src="../assets/phoneImage.png" /> -->
      </section>

      <section id="separatorAbout" >
            <H3 class="subHeadingText" style="margin-top: 40px;">About</H3>
            <span class="text">Play On is the perfect mobile app for tennis players who want to stay fit and have fun at
              the same time. With Play On, you can connect with players all over the world, regardless of skill level or
              location. Exchange messages and coordinate matches with ease. The app even offers interactive scoreboards
              and match statistics to track progress and competition. <br /> <br /> Whether you’re looking for a workout
              partner or to
              find your next big tournament opponent, Play On is the perfect tool to help get you connected. Get your game
              on and start playing today!</span>
      </section>

      <section id="problems" style="margin-top: 100px;">
        <center>
          <span class="problemsSubheader">our <span style="color: #ABCC25;">Features</span></span>
        </center>

        <v-row style="margin-top:20px">
          <v-col class="problemContainer">
            <h5 class="problemHeaderText">01</h5>
            <span class="text">
              Find tennis partner or court during a business trip in another city
            </span>
          </v-col>

          <v-col class="problemContainer">
            <h5 class="problemHeaderText">02</h5>
            <span class="text">
              Play with different opponents, score and collect results
            </span>
          </v-col>

          <v-col class="problemContainer">
            <h5 class="problemHeaderText">03</h5>
            <span class="text">
              Find new acquaintances with similar interests and become friends
            </span>
          </v-col>
        </v-row>
      </section>


      <section id="links" class="linksSection d-flex justify-center mb-6 bg-surface-variant">
        <router-link to="/SignIn" class="link">
          <span>SignIn</span>
        </router-link>
        <router-link to="/PrivacyPolicy" class="link">
          <span>Privacy Policy</span>
        </router-link>
        <router-link to="/ToS" class="link">
          <span>Terms of Use</span>
        </router-link>
      </section>
    </section>
  </v-container>
</template>
  
<script>
/* eslint-disable */
export default {
  name: 'Landing',

  data: () => ({

  }),
  methods: {
    isMobile() {
      // return true
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    }
}
</script>

<style scoped>
.TopScreen {}

.TopScreenLeft {
  flex-direction: column;
  padding: auto;
  background-color: #E8E8E8;
  height: 700px;
}

.iconContainer {
  width: 80px;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
  margin-left: 80px;
}

.HeadingText {
  font-family: 'Inter', sans-serif;
  font-size: 60pt;
  font-weight: 500;
  margin-left: 80px;
}

.subHeadingText {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #636371;
  font-size: 20pt;
  line-height: normal;
  z-index: 3;
}



.TopScreenRight {
  flex-direction: column;
  padding: auto;
  background-color: #D7F06F;
  width: 50%;
}

.mapImage {
  width: 70%;
  justify-self: flex-start;
  margin-left: -10px;
  margin-top: 20px;
}

/* .phoneImage {
  margin-left: -330px;
  margin-top: -450px;
  width: 500px;
} */
.phoneImage {
  width: 500px;
  display: block;
  margin-left: 25%;
  margin-top: -750px;
}

.separatorAbout {
  background-color: #fff;
  border-radius: 50%;
  height: 300px;
  width: 150%;
  margin-left: -20%;
  margin-top: -100px;
  position: relative;
  padding: 20%;
  padding-right: 40%;
}

.text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #262628;
  font-size: 12pt;
}

.problemsSubheader {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #636371;
  font-size: 20pt;
  line-height: normal;
  align-items: center;
  text-align: center;
}

.problemContainer {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background-color: #F8F8F8;
  margin: 10px;
  min-width: 250px;
}

.problemHeaderText {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  color: #ABCC25;
  margin-bottom: 20px;
}

.linksSection {
  margin-top:80px;
}

.link{ 
  margin: 10px;
  text-decoration: none; 
  color: #ABCC25;
}


.storeButtonsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.storeButtonsContainerMob {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


</style>
  