<template>
    <div>
      <ClubsTable/>
      <Regions />
    </div>
</template>
  
  <script>
  /* eslint-disable */

  import ClubsTable from '../components/ClubsTable.vue'
  import Regions from '../components/Regions.vue'

  export default {
    components: {ClubsTable, Regions},
    name: 'Clubs',
    data: () => ({
      //
    }),
  };
  </script>
  