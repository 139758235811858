<template>
    <v-row style="margin-left:50px; margin-top: 50px; justify-content: center;">
        <v-card width="95%" elevation="5"
  outlined
  style="border-radius:20px">
  <div style="margin-top:15px"/>
            <v-data-table :search="search" :headers="headers" :items="items" :items-per-page="40">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>News</v-toolbar-title><br />
                        <v-spacer></v-spacer>
                        <v-btn outlined style="margin-right:20px" @click="openNewDialog()">
                            <v-icon>mdi-plus</v-icon> Add new
                        </v-btn>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="viewCard(item)">
                        mdi-eye
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <!--Uploading-->
        <v-dialog
      v-model="upLoading"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

        <!--Dialog---->
        <v-dialog width="80%" v-model="dialog" persistent transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="dark">
                    <v-btn icon dark @click="cleanData()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn v-if="isNew" dark text @click="addNews()">
                            Add
                        </v-btn>
                        <v-btn v-if="!isNew" dark text @click="saveNews()">
                            Save
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div style="margin:10px" > 

                <v-subheader>General info</v-subheader>
                <v-row>
                <v-col cols="6" md="4">
                <v-select dense :items="status" label="Visability status" outlined v-model="itemData.Status"></v-select>
                </v-col>
                <v-col cols="6" md="4">
                <v-select dense :items="newsType" label="Type" outlined v-model="itemData.Type"></v-select>
                </v-col>
                <v-col cols="6" md="4">
                <v-text-field dense label="Date" type="date" outlined v-model="itemData.Date"></v-text-field>
                </v-col>
            </v-row>
            <v-row style="margin-top:-20px">
                <v-col>
                <v-text-field dense label="Title" outlined v-model="itemData.Title"></v-text-field>
                </v-col>
                <v-col>
                <v-text-field dense label="Sub-Title" outlined v-model="itemData.SubTitle"></v-text-field>
                </v-col>
            </v-row>
                <v-textarea counter outlined dense label="Text" v-model="itemData.NewsText"> </v-textarea>
                <div v-if="!isNew">
                <v-subheader style="margin-top:-20px">Statistics</v-subheader>
                <v-row>
                    <v-col>
                <v-text-field dense label="Views" disabled readonly outlined v-model="itemData.ViewsNumber"></v-text-field>
                    </v-col>
                    <v-col>
                <v-text-field dense label="Comments" disabled readonly outlined v-model="comments.length"></v-text-field>
            </v-col>
                </v-row>
                </div>

                <v-subheader v-if="isNew" style="margin-top:-30px">Images </v-subheader>
                <v-subheader v-if="!isNew" style="margin-top:-30px">Replace Current images with new:</v-subheader>
                <div v-for="(fileToUpload, index) in filesToUpload" :key="index">
                    <v-file-input label="Image file" key="fileToUpload" v-model="filesToUpload[index]" outlined dense :rules="imagesUploadRules" accept="image/jpeg"></v-file-input>
          </div>
          <div style="margin-top:-10px">
            <v-btn style="float: right;" color="success" @click="uploadFile()">Upload photos</v-btn>
            <v-btn v-if="this.filesToUpload.length < 6" style="float: right; margin-right: 10px" dark dense x-small fab elevation="0" color="success" @click="addFileInput()">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
            <v-btn v-if="this.filesToUpload.length > 1" style="float: right; margin-right: 10px" fab dark outlined x-small
              color="error" @click="removeFileInput()">
              <v-icon dark>mdi-minus</v-icon>
            </v-btn>
          </div>
                <div v-if="!isNew" style="margin-top:50px">
                 <v-divider/>
                 <v-subheader style="margin-top:0px">Current images preview</v-subheader>
                 <v-row>
                    <v-col cols="6" md="4" v-for="n in imageSource.length" :key="n">
                        <v-card width="100%" outlined style="padding:5px">
                            <center> <span style="font-size: 0.875rem; color:grey"> Image: {{n}} </span> </center>
                        <v-img :src=imageSource[n-1] /> 
                        </v-card>
                    </v-col>
                 </v-row>
                </div>

                <div v-if="!isNew" style="margin-top:20px">
                 <v-divider/>
                <v-subheader>Comments</v-subheader>
                <span v-if="comments.length < 1">No comments yet</span>
                <div v-for="(comment, index) in comments" :key="index">
                    <v-card key="comment" width="99%" elevation="1" outlined style=" margin-bottom:10px">
                        <span style="margin-left:10px; display: block; color: grey;"> {{ comment.UserName }} (Id: {{ comment.UserId }}) || {{ comment.Date }}</span>
                        <span style="margin-left:10px; display: block"> {{ comment.CommentText }}</span>
                        <v-icon style="margin-left:10px; color:grey" small >mdi-cards-heart</v-icon><span > {{ comment.Likes }}</span> <v-btn text color="error" @click="deleteComment(index)"> Delete</v-btn>
                        <!-- <v-card-subtitle> {{ comment.UserName }} || Date</v-card-subtitle>
                        <v-card-text>Text</v-card-text>
                        <v-card-actions>Likes || Ban</v-card-actions> -->
                    </v-card>
                </div>
                </div>



                </div>
                <div style="margin-top:60px">
                    <center>
                        <v-btn style="width:100%" v-if="isNew" color="success"  @click="addNews()">
                            Add News
                        </v-btn>
                        <v-btn style="width:100%" v-if="!isNew" color="warning"  @click="saveNews()">
                            Save changes
                        </v-btn>
                    </center>
                    </div>
            </v-card>
        </v-dialog>

    </v-row>
</template>
<script>
/* eslint-disable */

import { nanoid } from 'nanoid'
import {
    getNews,
    updateNews,
    addNews,
    uploadImage
} from '../services/news-service'

export default {
    name: 'NewsTable',
    data: () => ({
        comments: [],
        newsType: ['Latvia', 'Spain', 'Global', 'Tennis', 'Padel'],
        status: ['Visible', 'Hidden'],
        upLoading: false,
        imageSource: [],
        imagesUploadRules: [
        value => !value || value.size < 1000000 || 'Image size should be less than 1 MB!',
      ],
        NumberOfImages: 0,
        filesToUpload: [undefined],
        isNew: false,
        dialogTitle: null,
        dialog: false,
        itemData: [],
        items: [],
        headers: [
      {
        width: "120px",
        text: "Date",
        align: "start",
        sortable: true,
        value: "Date",
      },
      {
        width: "120px",
        text: "Type",
        align: "start",
        sortable: true,
        value: "Type",
      },
      {
        text: "Title",
        align: "start",
        sortable: true,
        value: "Title",
      },
      {
        width: "100px",
        text: "Status",
        align: "start",
        sortable: true,
        value: "Status",
      },
      {

        width: "90px",
        text: "Views",
        align: "start",
        sortable: true,
        value: "ViewsNumber",
      },
      {

        width: "90px",
        text: "Likes",
        align: "start",
        sortable: true,
        value: "LikesNumber",
      },
      {
        width: "120px",
        text: "Comments",
        align: "start",
        sortable: true,
        value: "Comments.length",
      },
      {
        width: "200px",
        text: "ID",
        align: "start",
        sortable: false,
        value: "Id",
      },
      { 
        width: "120px", text: 'Actions', value: 'actions' },
    ],
    search: '',
    }),

    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            getNews().then((res) => {
                //console.log(res)
                this.items = res
            })
        },
        cleanData() {
            this.filesToUpload = [undefined]
            this.comments = []
            this.itemData = []
            this.dialogTitle = null
            this.isNew = false
            this.imageSource = [undefined]
            this.upLoading = false
            this.items = []
            this.dialog = false
            this.getData()
        },
        openNewDialog() {
            this.dialogTitle = "Add a new News"
            this.isNew = true
            this.dialog = true
        },
        viewCard(item) {
            console.log("item", item)
            this.itemData = item
            if (item.Comments) {this.comments = item.Comments}
            //console.log(this.comments.length)
            this.dialogTitle = "News Id: " + this.itemData.Id
            this.isNew = false
            this.dialog = true
            for (let index = 0; index < this.itemData.Images.length; index++) {
                this.imageSource[index] = "https://firebasestorage.googleapis.com/v0/b/tennis-948b2.appspot.com/o/Images%2FNews%2F"+this.itemData.ImagesFolderId+"%2FImage"+[index]+"?alt=media"
                this.NumberOfImages++
            }
        },
        async addNews() {
            this.upLoading = true
            let newData = this.itemData
            newData.ViewsNumber = 0
            newData.LikesNumber = 0
            newData.Comments = []
            await addNews(newData).then((res) => {
                console.log("News has been ADDED", res)
                this.cleanData()
            })
        },
        async saveNews() {
            this.upLoading = true
            let newData = this.itemData
            newData.Comments = this.comments
            await updateNews(newData).then((res) => {
                console.log("News database UPDATED! ", res)
                this.cleanData()
            })
        },
        uploadFile() {
            this.upLoading = true
            if (!this.itemData.ImagesFolderId) this.itemData.ImagesFolderId = nanoid(10)
            uploadImage(this.filesToUpload, this.itemData.ImagesFolderId).then((res) => {
            this.itemData.Images = res.ImagesData
            console.log("Images UPLOADED to server ", this.itemData.Images)
            if (this.isNew == false) {
                this.saveNews()
            } else this.upLoading = false
            })
        },
        addFileInput(){
            if (this.filesToUpload.length < 6) {
                this.filesToUpload.push(undefined)
      }
        },
        removeFileInput(){
            if (this.filesToUpload.length > 1) {
        this.filesToUpload.pop();
      }
        },
        deleteComment(index){
            console.log("Delete comment with index: ", index)
            this.comments.splice(index,1)
        },
    }
}
</script>
